$primary: #019191;
//$primary: #2A3893;
//$primary: #2A3893;
$secondary: #717171;
$success: #0EB03B;
$danger: #E02A40 ;//
$warning: #FF9900;
$warning: #cc7a00;
$info: #0097FB;
$light: #F4F4F4;
$dark: #2A3893;
//$dark: #4e4e4e;

.btn-success,
.btn-success:hover,
.btn-success:focus
{
    color: #fff;
}

.btn-warning,
.btn-warning:hover,
.btn-warning:focus
{
    color: #fff;
}

.btn-info,
.btn-info:hover,
.btn-info:focus
{
    color: #fff;
}

.text-warning {
    color: $warning !important;
}
