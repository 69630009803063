@import "styles.variables";
@import "~bootstrap/scss/functions";

.alert-modal {
    .modal-content {
        border: none;
        border-radius: 10px;
    }
}

.alert-modal-header {
    border-color: #C5C5C5;

    font-size: 18px;
    font-weight: 700;
    color: $primary;
}


.alert-content-wrapper {
    margin-top: -1.5rem;
}

.alert-icon {
    width: 75px;
    height: auto;
    margin-bottom: 1rem;
}

.alert-content {
    margin-bottom: 0.5rem;
}

.alert-title {
    font-size: 22px;
    font-weight: 600;

}

.alert-body {
    font-size: 18px;
    font-weight: 400;

}

.modal-button {
    .btn {
        min-width: 100px;
    }
}
