.btn-success,
.btn-success:hover,
.btn-success:focus {
  color: #fff;
}

.btn-warning,
.btn-warning:hover,
.btn-warning:focus {
  color: #fff;
}

.btn-info,
.btn-info:hover,
.btn-info:focus {
  color: #fff;
}

.text-warning {
  color: #cc7a00 !important;
}

.btn-success,
.btn-success:hover,
.btn-success:focus {
  color: #fff;
}

.btn-warning,
.btn-warning:hover,
.btn-warning:focus {
  color: #fff;
}

.btn-info,
.btn-info:hover,
.btn-info:focus {
  color: #fff;
}

.text-warning {
  color: #cc7a00 !important;
}

.custom-form-style .form-control {
  border-color: #F4F4F4;
  background-color: #F4F4F4;
  border-radius: 5px;
}
.custom-form-style .form-control:focus {
  border-color: #019191;
  background-color: #FFFFFF;
}
.custom-form-style .btn {
  border-radius: 5px;
}

.check-car-form label {
  display: none;
}
.check-car-form.onconfirm label {
  display: inline-block;
}
.check-car-form .form-control {
  background: #FFFFFF;
  border: 1px solid #FF9900;
  border-radius: 5px;
}
.check-car-form .form-control:disabled {
  background-color: #F4F4F4;
}

form.submitted .ng-touched.ng-invalid:not(form),
form.submitted .ng-untouched.ng-invalid:not(form),
.ng-touched.ng-invalid:not(form) {
  border-color: #E02A40 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E02A40' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E02A40' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
form.submitted .ng-touched.ng-invalid ~ .invalid-feedback,
form.submitted .ng-untouched.ng-invalid ~ .invalid-feedback,
.ng-touched.ng-invalid ~ .invalid-feedback {
  display: block;
}

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
.custom-form-style .form-control {
  border-color: #F4F4F4;
  background-color: #F4F4F4;
  border-radius: 5px;
}
.custom-form-style .form-control:focus {
  border-color: #019191;
  background-color: #FFFFFF;
}
.custom-form-style .btn {
  border-radius: 5px;
}

.check-car-form label {
  display: none;
}
.check-car-form.onconfirm label {
  display: inline-block;
}
.check-car-form .form-control {
  background: #FFFFFF;
  border: 1px solid #FF9900;
  border-radius: 5px;
}
.check-car-form .form-control:disabled {
  background-color: #F4F4F4;
}

form.submitted .ng-touched.ng-invalid:not(form),
form.submitted .ng-untouched.ng-invalid:not(form),
.ng-touched.ng-invalid:not(form) {
  border-color: #E02A40 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E02A40' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E02A40' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
form.submitted .ng-touched.ng-invalid ~ .invalid-feedback,
form.submitted .ng-untouched.ng-invalid ~ .invalid-feedback,
.ng-touched.ng-invalid ~ .invalid-feedback {
  display: block;
}

.VideoWrapper {
  position: relative;
  display: none;
}
.VideoWrapper.callStarted {
  display: block;
}
.VideoWrapper .Video-control-top {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 9;
}
.VideoWrapper .Video-control-bottom {
  position: absolute;
  bottom: 10px;
  width: 100%;
  z-index: 9;
}
.VideoWrapper .Video-control {
  display: flex;
  justify-content: center;
  align-items: center;
}
.VideoWrapper .Video-control .btn-control {
  color: #4e4e4e;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0);
  border-radius: 100px;
  min-width: 40px;
  height: 40px;
  text-align: center;
  padding: 0 10px;
  line-height: 40px;
  margin: 0 5px;
}
.VideoWrapper .Video-control .btn-control:hover {
  background-color: #e4e4e4;
}
.VideoWrapper .Video-control .btn img {
  display: block;
}
.VideoWrapper .Video-control .btn-phone {
  margin: 0 5px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100px;
}
.VideoWrapper .Video-control {
  opacity: 0.35;
  transition: opacity 0.15s ease-out;
}
.VideoWrapper:hover .Video-control {
  opacity: 1;
}
.VideoWrapper .VideoBoxWrapper {
  position: relative;
  background-color: #1f1f1f;
  justify-content: center;
}
.VideoWrapper.overlayMode .VideoBox {
  background-color: rgba(228, 228, 228, 0.21);
}
.VideoWrapper.overlayMode .video {
  line-height: 0;
  display: block;
  max-width: 100%;
  padding: 5px;
  width: auto;
}
.VideoWrapper.overlayMode #myVideoBox {
  background-color: rgba(177, 177, 177, 0.4);
}
.VideoWrapper.overlayMode.not-swap #yourVideoBox .video {
  height: 100%;
  width: 100vw;
}
.VideoWrapper.overlayMode.not-swap #myVideoBox {
  position: absolute;
  min-width: 160px;
  width: 15%;
  height: auto;
  right: 10px;
  top: 10px;
}
.VideoWrapper.overlayMode.not-swap #myVideoBox .video {
  width: 100%;
}
.VideoWrapper.overlayMode.swap #myVideoBox .video {
  height: 100%;
  width: 100vw;
}
.VideoWrapper.overlayMode.swap #yourVideoBox {
  position: absolute;
  min-width: 160px;
  width: 15%;
  height: auto;
  right: 10px;
  top: 10px;
}
.VideoWrapper.overlayMode.swap #yourVideoBox .video {
  width: 100%;
}
.VideoWrapper.splitMode .video {
  height: 100%;
}
.VideoWrapper.splitMode .VideoBox {
  background-color: rgba(228, 228, 228, 0.21);
  height: 100%;
}
.VideoWrapper.splitMode .video {
  line-height: 0;
  display: block;
  max-width: 100%;
  padding: 5px;
  width: auto;
}
.VideoWrapper.splitMode #yourVideoBox {
  max-width: 75%;
}
.VideoWrapper.splitMode #myVideoBox {
  background-color: rgba(177, 177, 177, 0.4);
  max-width: 25%;
}
.VideoWrapper.splitMode.swap .VideoBoxWrapper {
  flex-direction: row-reverse;
}
.VideoWrapper.splitMode.swap .VideoBoxWrapper #yourVideoBox {
  max-width: 25%;
}
.VideoWrapper.splitMode.swap .VideoBoxWrapper #myVideoBox {
  background-color: #b1b1b1;
  max-width: 75%;
}
.VideoWrapper.customerMode .Video-control {
  opacity: 1;
}
@media (orientation: portrait) {
  .VideoWrapper.customerMode.splitMode .VideoBoxWrapper {
    flex-direction: column;
  }
  .VideoWrapper.customerMode.splitMode.swap .VideoBoxWrapper {
    flex-direction: column-reverse;
  }
  .VideoWrapper.customerMode.splitMode .video {
    width: 100%;
  }
}

.btn-success,
.btn-success:hover,
.btn-success:focus {
  color: #fff;
}

.btn-warning,
.btn-warning:hover,
.btn-warning:focus {
  color: #fff;
}

.btn-info,
.btn-info:hover,
.btn-info:focus {
  color: #fff;
}

.text-warning {
  color: #cc7a00 !important;
}

.alert-modal .modal-content {
  border: none;
  border-radius: 10px;
}

.alert-modal-header {
  border-color: #C5C5C5;
  font-size: 18px;
  font-weight: 700;
  color: #019191;
}

.alert-content-wrapper {
  margin-top: -1.5rem;
}

.alert-icon {
  width: 75px;
  height: auto;
  margin-bottom: 1rem;
}

.alert-content {
  margin-bottom: 0.5rem;
}

.alert-title {
  font-size: 22px;
  font-weight: 600;
}

.alert-body {
  font-size: 18px;
  font-weight: 400;
}

.modal-button .btn {
  min-width: 100px;
}

.signature-name label {
  font-size: 1.5em;
  margin: 0px;
}

.signature-filed {
  max-width: 30rem;
}
.signature-filed .SignatureImg {
  text-align: center;
}
.signature-filed .SignatureImg img {
  max-width: 100%;
  cursor: pointer;
  max-height: 5rem;
}
.signature-filed .SignatureText {
  font-size: 1.5rem !important;
}
.sign-modal {
  height: 100%;
}
.sign-modal .card {
  height: 100%;
}
.sign-modal .card .card-header {
  padding: 5px;
  text-align: center;
  background-color: #d7d9e2;
}
.sign-modal .card .card-header img {
  height: 25px;
}
.sign-modal .card .card-header .btn {
  background-color: transparent;
  border-color: transparent;
  padding: 0 10px;
}
.sign-modal .card .card-header .btn.active {
  border-bottom: 3px solid #2a3893;
  color: #2a3893;
  border-radius: 0;
}
.sign-modal .SignatureTextDisplay {
  border-bottom: 2px solid #414141;
}
.sign-modal .SignatureTextDisplay img {
  height: 2rem;
}
.sign-modal .SignatureImgBox {
  position: relative;
}
.sign-modal .SignatureImgBox #fileDropRef {
  display: none;
}
.sign-modal .SignatureImgBox.active {
  height: 100%;
}
.sign-modal .SignatureImgBox.active #fileDropRef {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.sign-modal .SignatureImgBox .SignatureImg {
  height: 100%;
  text-align: center;
}
.sign-modal .SignatureImgBox .SignatureImg img {
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}
.sign-modal .SignaturePadBox {
  height: 100%;
}
.sign-modal .SignaturePadBox canvas {
  background-color: #e4e4e4;
}
.sign-modal .signaturePadWrapper {
  position: relative;
  height: 100%;
}
.sign-modal .signaturePadWrapper .btn-box {
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (orientation: portrait) {
  .sign-modal .signaturePadWrapper {
    min-height: 250px;
  }
}
.sign-modal .add-img-btn {
  display: inline-block;
  background-color: #e4e4e4;
  border-radius: 500px;
  padding: 1rem;
}

.sign-tools {
  position: absolute;
  bottom: 10px;
  left: 0px;
}
.sign-tools .icon {
  display: block;
}
.sign-tools .active-icon {
  display: none;
}
.sign-tools .active .icon {
  display: none;
}
.sign-tools .active .active-icon {
  display: block;
}

@media (orientation: landscape) {
  .modal-full-screen .modal-dialog {
    align-items: stretch;
    max-width: 80%;
    /*
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 15px 0; // */
  }
}

.sign-modal-wrapper .modal-content {
  border-radius: 6px 6px 12px 12px;
  overflow: hidden;
  border: none;
}

.sign-modal {
  border-radius: 10px;
}
.sign-modal .sign-footer {
  padding: 0;
}
.sign-modal .sign-footer .btn {
  padding: 15px 0;
  border-radius: 0;
  border-left: none;
  border-color: #e4e4e4;
}
.sign-modal .sign-footer .row-btn .col:first-child .btn {
  border-radius: 0 0 0 10px;
}
.sign-modal .sign-footer .row-btn .col:last-child .btn {
  border-radius: 0 0 10px 0;
  border-right: none;
}

.liff-layout {
  margin: auto;
  max-width: 767px;
}
.liff-layout .liff-banner {
  margin-bottom: 1rem;
}
.liff-layout .liff-banner .banner-img {
  max-height: 280px;
}
.liff-layout .page-title {
  color: #cc7a00;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.alert-modal .modal-content {
  border: none;
  border-radius: 10px;
}

.alert-modal-header {
  border-color: #c5c5c5;
  font-size: 18px;
  font-weight: 700;
  color: #cc7a00;
}

.video-call-card-wrapper {
  min-height: 100%;
  padding: 10px;
}
.video-call-card-wrapper.hide {
  display: none;
}

.video-call-card .card-body {
  padding: 5px;
  background-color: #1f1f1f;
}
.video-call-card .card-header {
  color: #388ad8;
  font-weight: 500;
  padding: 10px 10px;
}
.video-call-card .card-footer {
  color: #388ad8;
  font-weight: 500;
  padding: 5px 5px;
}

.video-call-form-wrapper {
  min-height: 100%;
  padding: 10px;
  background-color: #dedede;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.video-call-form-wrapper .card-header {
  color: #388ad8;
  font-weight: 500;
  padding: 10px 5px;
}
.video-call-form-wrapper .card-footer {
  color: #388ad8;
  font-weight: 500;
  padding: 10px 5px;
}
.video-call-form-wrapper .form-control {
  background-color: #e8e8e8;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  font-weight: 500;
  text-align: center;
}
.video-call-form-wrapper .form-control.isRegistered {
  background-color: #bad8b7;
  border-color: #bad8b7;
}
.video-call-form-wrapper .btn-start {
  min-width: 150px;
}
.video-call-form-wrapper .video-call-form-card {
  max-width: 450px;
  margin: auto;
}

.callingModal {
  padding: 0 30px;
}
.callingModal .modal-content {
  border-radius: 10px;
}

.callingModal-header {
  text-align: center;
  justify-content: center;
}
.callingModal-header .modal-title {
  font-size: 18px;
  color: #ff9900;
}

.callingModal-body {
  padding: 0;
}
.callingModal-body .modal-body-inner {
  padding: 25px 15px;
}
.callingModal-body .btn {
  border-radius: 0;
  border-left: none;
}
.callingModal-body .row-btn .col:first-child .btn {
  border-radius: 0 0 0 10px;
}
.callingModal-body .row-btn .col:last-child .btn {
  border-radius: 0 0 10px 0;
  border-right: none;
}

.btn-round {
  border-radius: 100px;
}

.condition-modal .modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
}

.check-tabs {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.check-tab-item {
  text-align: center;
}
.check-tab-item .nav-link {
  display: inline-block;
  padding: 10px 10px;
  color: #696969;
  font-weight: 600;
}
.check-tab-item .nav-link.active {
  color: #ff9900;
  border-bottom: 4px solid #ff9900;
}

.check-car-body {
  overflow-y: auto;
}

.appointment-calendar ngb-datepicker {
  /* display: inline-block; */
  width: 100%;
  border: none;
}
.appointment-calendar .custom-select {
  color: #019191;
  font-weight: 600;
  border: none;
  background-color: #eeeeee;
  margin-right: 5px;
}
.appointment-calendar .ngb-dp-header,
.appointment-calendar .ngb-dp-week {
  background-color: transparent;
  border: none;
}
.appointment-calendar .ngb-dp-header {
  margin-bottom: 10px;
}
.appointment-calendar .ngb-dp-weekday {
  width: 14.285%;
  height: auto;
  padding: 5px 0;
  text-align: center;
  color: #019191;
  color: #000000;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
}
.appointment-calendar .ngb-dp-month {
  width: 100%;
}
.appointment-calendar .ngb-dp-day {
  position: relative;
  width: 14.285%;
  height: auto;
}
.appointment-calendar .ngb-dp-day .day-number {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin: 5px auto;
  background-color: #f3f2f2;
  border-radius: 50px;
  text-align: center;
  font-weight: 600;
}
.appointment-calendar .ngb-dp-day .outside-month {
  color: #9a9a9a;
  background-color: transparent;
}
.appointment-calendar .ngb-dp-day.ngb-dp-today .day-number {
  border: 2px solid #019191;
  border-radius: 50px;
}
.appointment-calendar .ngb-dp-day .day-selected {
  background-color: #019191;
  color: #ffffff;
}
.appointment-calendar .ngb-dp-day .day-close {
  color: #E02A40;
}
.appointment-calendar .ngb-dp-day .day-close.day-selected {
  background-color: #E02A40;
  color: #fff;
}
.appointment-calendar .ngb-dp-day .count-appointment {
  width: auto;
  height: auto;
  line-height: 1rem;
  position: absolute;
  color: #ffffff;
  background-color: #E02A40;
  padding: 1px 5px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 12px;
  left: 55%;
  bottom: 0px;
  min-width: 18px;
  text-align: center;
}

.table-my-list {
  font-size: 14px;
}
.table-my-list .table thead th {
  background-color: #019191;
  color: #ffffff;
  padding: 7.5px 3px;
  border-color: #019191;
}

.btn-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  border-radius: 50px;
}

.modal-title {
  color: #019191;
}

.appointment-detail .form-control {
  background: #ffffff;
  border: 1px solid #ff9900;
  border-radius: 5px;
}

.form-hide-label label {
  display: none;
}

.text-title {
  color: #019191;
}