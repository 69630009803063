.custom-form-style {
    .form-control {
        border-color: #F4F4F4;
        background-color: #F4F4F4;
        border-radius: 5px;

        &.is-invalid {
            // border-color: #E02A40;
        }
    }

    .form-control:focus {
        border-color: $primary;
        background-color: #FFFFFF;
    }

    .btn {
        border-radius: 5px;
    }
}
.check-car-form {
    label {
        display: none;
    }

    &.onconfirm {
        label {
            display: inline-block;
        }
    }

    .form-control {
        background: #FFFFFF;
        border: 1px solid #FF9900;
        border-radius: 5px;

        &:disabled {
            background-color: #F4F4F4;
        }
    }
}

form.submitted .ng-touched,
form.submitted .ng-untouched,
.ng-touched {
    &.ng-invalid:not(form) {
        border-color: #E02A40 !important;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E02A40' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E02A40' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    &.ng-invalid ~ .invalid-feedback {
        display: block;
    }
}
